import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { StyledCard as Card } from "./card";
import ShaderGIF from '../../images/gifs/shader.gif';
import PhysicsGIF from '../../images/gifs/phisics.gif';
import ExperimentsGIF from '../../images/gifs/experiments.gif';
import PortfolioGIF from '../../images/gifs/portfolio.gif';
import NijisanjibotPNG from '../../images/nijisanjibot.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`自分の作ったものを載せていきます。随時更新予定です。`}</p>
    <ul>
      <li parentName="ul"><Card href={"https://hpprc.com"} mdxType="Card">
  <img src={PortfolioGIF} alt='my portfolio site' />
  <h2>Portfolio Site</h2>
  <p>自分のポートフォリオサイトです。TypeScript + Gatsby.jsでサイトの全体を書いていて、ヒーローイメージはreact-three-fiberを使ってWebGLからShaderをいじっています。Netlify CMSを使って管理画面からブログ記事を作成・投稿できます。さらにMDX(JSX in Markdown)という闇技術で、ブログ記事中でもReactコンポーネントをimportして使えたりもします。全部載せ感。</p>
        </Card></li>
      <li parentName="ul"><Card href={"https://hpprc.github.io/generativereact/"} mdxType="Card">
  <img src={ExperimentsGIF} alt='experiments site' />
  <h2>Generative React</h2>
  <p>Generative Artが用いられているSPAってあんまりないよな〜的な気持ちから作ったサイトです。もとはp5.jsで作ったスケッチなどの作品を置いておくための倉庫的な気持ちで作ったサイトです。その後react-three-fiberを使った作品の実験場みたいな感じになりました。Gatsbyで書き直したい...🔥</p>
        </Card></li>
      <li parentName="ul"><Card href={"https://hpprc.github.io/generativereact/#/works/12"} mdxType="Card">
  <img src={ShaderGIF} alt='shader experiments' />
  <h2>Shader Experiments</h2>
  <p>react-three-fiber(React用のThree.jsライブラリ)を触っているときに錬成されたものです。shaderがめちゃくちゃ楽しいので困ります。</p>
        </Card></li>
      <li parentName="ul"><Card href={"https://github.com/hppRC/2434illustbot"} mdxType="Card">
  <img src={NijisanjibotPNG} alt='nijisanji illust bot' />
  <h2>2434 Illust bot</h2>
  <p>にじさんじのイラストbotです(名前のまんま)。Rustで直接Twitter APIを叩いて動かしています。アプリはherokuにデプロイされており、定期実行の機能もあります。たまにAPIの権限が吹っ飛ぶので定期実行できていない時があります。</p>
        </Card></li>
      <li parentName="ul"><Card href={"https://hpprc.github.io/generativereact/#/works/7"} mdxType="Card">
  <img src={PhysicsGIF} alt='Physics experiments' />
  <h2>Physics Experiments</h2>
  <p>react-three-fiberでCANNON.jsという物理演算ライブラリを使って遊んでみたやつです。これでゲームを作ってる方もいて可能性無限か？と思っています。</p>
        </Card></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      